import React, { useState, useRef, useEffect } from "react";
import Line from "./line";
import NpmStartSection from "./npm-start";
import NpmStartedSection from "./npm-started";
import NpmEndedSection from "./npm-ended";
import ConsoleHeading from "./console-heading";
import { useInViewEffect } from "react-hook-inview";

function* testLinesConfigGenerator() {
  let config = [
    [0, "when working for you", "describe"],
    [1, "can implement", "describe"],
    [2, "sustainable test strategy", "test_pass"],
    [2, "reliable automated testing", "test_pass"],
    [2, "readable and meaningful test reporting", "test_pass"],
    [2, "flakey automated tests", "test_fail"],
    [1, "can automate front end tests with", "describe"],
    [2, "selenium", "test_pass"],
    [2, "cypress", "test_pass"],
    [2, "protractor", "test_pass"],
    [2, "puppeteer", "test_pass"],
    [1, "can develop tests in", "describe"],
    [2, "JavaScript", "test_pass"],
    [2, "C#", "test_pass"],
    [2, "Java", "test_pass"],
    [1, "can integrate test execution within a CI pipeline that", "describe"],
    [2, "runs on build or deployment", "test_pass"],
    [2, "generates accessible test reports", "test_pass"],
    [2, "notifies teams of test failures", "test_pass"],
    [0, "is an advocate of", "describe"],
    [1, "shifting quality assurance to the left", "test_pass"],
    [1, "QA Engineers as Gatekeepers", "test_fail"],
    [1, "testing at the right level", "test_pass"],
    [1, "testing boundaries & equivalence partitioning", "test_pass"],
    [1, "keeping tests simple", "test_pass"],
    [1, "using existing test frameworks and best practice", "test_pass"],
    [0, "has experience of writing tests for", "describe"],
    [1, "complex financial calculations", "test_pass"],
    [1, "FCA regulated financial advice software", "test_pass"],
    [1, "serverless architecture", "test_pass"],
    [1, "IOT interfacing software", "test_pass"],
    [1, "image analysis algorithms", "test_pass"],
    [1, "microservice architecture solutions", "test_pass"],
  ];

  for (let i = 0; i < config.length; i++) {
    yield config[i];
  }
}

const Console = () => {
  const [testLinesConfig] = useState(testLinesConfigGenerator());

  const timers = {
    startTimeout: useRef(),
    testRunInterval: useRef(),
  };
  const [autoScrollCancelled, setAutoScrollCancelled] = useState(false);
  const [lastPosition, setLastPosition] = useState();
  const [npmStart, setNpmStart] = useState(false);
  const [npmStarted, setNpmStarted] = useState(false);
  const [testLines, setTestLines] = useState([]);
  const [testOutcome, setTestOutcome] = useState({
    test_pass: 0,
    test_fail: 0,
  });
  const [testLinesDone, setTestLinesDone] = useState(false);
  const consoleLineContainer = useRef(null);

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);

        setNpmStart(true);
        timers.startTimeout.current = setTimeout(() => {
          setNpmStarted(true);
          startTestRun();
        }, 2000);
      }
    },
    { threshold: 0.5 }
  );

  const scrollToBottomOfConsole = () => {
    if (consoleLineContainer && consoleLineContainer.current) {
      consoleLineContainer.current.scrollTop =
        consoleLineContainer.current.scrollHeight;
    }
  };

  const clearTimers = () => {
    if (timers.testRunInterval.current) {
      clearInterval(timers.testRunInterval.current);
    }

    if (timers.startTimeout.current) {
      clearTimeout(timers.startTimeout.current);
    }
  };

  useEffect(() => clearTimers, []);

  const startTestRun = (scrollToBottom = true) => {
    timers.testRunInterval.current = setInterval(() => {
      const nextLine = testLinesConfig.next();
      if (nextLine.done) {
        clearTimers();
        setTestLinesDone(true);
      } else {
        setTestLines((testLines) => [...testLines, nextLine.value]);
        setTestOutcome((outcome) => {
          // eslint-disable-next-line no-unused-vars
          const [_ignoredOne, _ignoredTwo, type] = nextLine.value;

          outcome[type]++;

          return { ...outcome };
        });
      }
      if (scrollToBottom) {
        scrollToBottomOfConsole();
      }
    }, 150);
  };

  const cancelAutoScroll = () => {
    clearTimers();
    startTestRun(false);
    setAutoScrollCancelled(true);
  };

  return (
    <div ref={ref} className="relative w-full">
      <ConsoleHeading />
      <div
        onScroll={(e) => {
          if (!autoScrollCancelled) {
            const newPosition = e.currentTarget.scrollTop;

            if (newPosition < lastPosition - 30) {
              cancelAutoScroll();
            }

            setLastPosition(newPosition);
          }
        }}
        onTouchStart={(e) => {
          if (!autoScrollCancelled) {
            const lastPosition = e.touches[0].clientY;
            setLastPosition(lastPosition);
          }
        }}
        onTouchMove={(e) => {
          if (!autoScrollCancelled) {
            const newPosition = e.changedTouches[0].clientY;

            if (lastPosition < newPosition - 30) {
              cancelAutoScroll();
            }
          }
        }}
        ref={consoleLineContainer}
        className="w-full h-full md:h-128 landscape-md:h-full landscape-lg:h-128 bg-gray-900 border-gray-700 border-2 p-3 pt-10 overflow-y-scroll terminal terminal-scrollbar"
      >
        {npmStart && <NpmStartSection showBlinker={!npmStarted} />}
        {npmStarted && <NpmStartedSection />}
        {testLines.map(([indentation, wording, type], i) =>
          Line(indentation, wording, type, i)
        )}
        {testLinesDone && (
          <NpmEndedSection
            passing={testOutcome.test_pass}
            failing={testOutcome.test_fail}
          />
        )}
      </div>
    </div>
  );
};

export default Console;
