import React, { useRef } from "react";
import { useInView } from "react-hook-inview";
import TypedText from "../typed-text";
import DownloadCV from "./download-cv";
import Console from "./console/index";

const testerSynonyms = [
  "QA Engineer",
  "Developer in Test",
  "Software QA",
  "Software Tester",
  "Test Developer",
  "Automated Tester",
];

function Splash() {
  const [firstPanelRef, firstPanelInView] = useInView({
    threshold: 0.6,
  });

  const secondPanelRef = useRef(null);

  const scrollToSecondPanel = () => {
    secondPanelRef.current.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  return (
    <section className="flex flex-wrap lg:flex-no-wrap self-center w-full mx-auto xl:max-w-screen-xl md:px-12">
      <div
        ref={firstPanelRef}
        className="flex justify-items-center items-center py-6 h-viewport md:h-auto landscape-md:h-viewport landscape-lg:h-auto"
      >
        <div className="splash-hero border-l-8 sm:border-l-4 border-green-700 p-6 md-min-w-128">
          <h1 className="text-6xl leading-tight">Tom Hudson</h1>
          <h2 className="text-4xl mt-0 mb-4 text-gray-600 splash-typed-text">
            <TypedText suffixes={testerSynonyms} />
          </h2>
          <DownloadCV />
        </div>
        <div
          id="find-out-more"
          role="second-panel"
          onClick={scrollToSecondPanel}
          className={`md:hidden absolute inset-x-0 bottom-0 text-center text-2xl transition duration-500 ease-in-out ${
            firstPanelInView ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="text-sm leading-none text-gray-700">
            find out more
          </div>
          <i className="pulsate icon icon-chevron-thin-down text-white pulse-text"></i>
        </div>
      </div>

      <div
        ref={secondPanelRef}
        className="flex justify-items-center py-6 w-full h-viewport md:h-auto landscape-md:h-viewport landscape-lg:h-auto"
      >
        <Console />
      </div>
    </section>
  );
}

export default Splash;
