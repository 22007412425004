import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Splash from "../components/home/splash";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="QA Engineer"
        description="Tom Hudson, QA Engineer, with over 6 years automated software testing experience and 12 years working in Software Development."
      />
      <Splash />
    </Layout>
  );
}

export default IndexPage;
