import React from "react";

const Tooltip = React.forwardRef(({ text }, ref) => (
  <div ref={ref} style={{ bottom: "100%" }} className="absolute pt-4">
    <div className="bg-gray-700 text-white px-6 py-4 text-sm mb-4 animate__animated animate__fadeInUp">
      {text}
    </div>
  </div>
));

export default Tooltip;
