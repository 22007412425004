import React from "react";

const NpmStartedSection = () => (
  <div className="mb-6">
    <div className="mb-6">> @tom-hudson.uk@1.0.12 npm test</div>
    <div className="w-full border-dashed border-t-2 border-gray-700"></div>
    <div className="w-100 text-center my-3">Running Tests</div>
    <div className="w-full border-dashed border-t-2 border-gray-700"></div>
  </div>
);

export default NpmStartedSection;
