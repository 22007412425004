import React from "react";

const calculateIndentationPadding = (indentation) =>
  `sm:pl-${indentation * 6} pl-${indentation * 4}`;

const getLineTypeGenerator = {
  test_pass: (wording, indentation, i) => (
    <div
      key={i}
      className={`${calculateIndentationPadding(
        indentation
      )} whitespace-pre-wrap text-gray-500`}
    >
      <i className="icon icon-checkmark text-green-500 mr-2 text-xs"></i>
      {wording}
    </div>
  ),
  test_fail: (wording, indentation, i) => (
    <div
      key={i}
      className={`${calculateIndentationPadding(
        indentation
      )} whitespace-pre-wrap text-gray-500`}
    >
      <i className="icon icon-cross text-red-500 mr-2 text-xs"></i>
      {wording}
    </div>
  ),
  describe: (wording, indentation, i) => (
    <div
      key={i}
      className={`${calculateIndentationPadding(indentation)} ${
        indentation === 0 ? "mt-4" : ""
      } whitespace-pre-wrap`}
    >
      {wording}
    </div>
  ),
};

const Line = (indentation, wording, type, i) => {
  const lineTypeGenerator = getLineTypeGenerator[type];

  return lineTypeGenerator(wording, indentation, i);
};

export default Line;
