import React, { useState, useRef } from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { useInView } from "react-hook-inview";
import Tooltip from "../tooltip";
import axios from "axios";

function DownloadCV() {
  const inputRef = useRef(null);
  const tooltipRef = useRef(null);
  const [emailContainer] = useInView({
    threshold: 0,
    onLeave: () => {
      cancel();
    },
  });

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errored, setErrored] = useState(false);
  const [inputOpen, setInputOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const setInputClasses = () => {
    return inputOpen
      ? `pl-1 sm:mr-4 w-full sm:w-56 ${submitting ? "opacity-50" : ""}`
      : "w-0";
  };

  const setButtonClasses = () => {
    return inputOpen && !isValid
      ? "focus:bg-transparent hover:bg-transparent hover:border-green-700 hover:text-green-500 opacity-50 cursor-not-allowed"
      : "hover:bg-green-500 hover:text-white ";
  };

  const onChange = (event) => {
    const validEmailAddress = validateEmail(event.target.value);

    setIsValid(validEmailAddress);

    if (validEmailAddress) {
      setEmailAddress(event.target.value);
    }
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return validEmailRegex.test(email);
  };

  const onDownloadCVButtonClick = () => {
    setInputOpen(true);

    if (typeof window !== "undefined") {
      document.dispatchEvent(new Event("virtualKeyboardOpening"));
    }

    inputRef.current.focus();
    setTimeout(() => {
      if (tooSmallForForTooltip()) {
        inputRef.current.scrollIntoView(true);
      } else {
        tooltipRef.current.scrollIntoView(true);
      }
    }, 50);

    trackCustomEvent({
      category: "download cv",
      action: "click",
      label: "Download CV",
    });
  };

  const requestDownloadLink = () => {
    if (isValid) {
      setSubmitting(true);

      axios
        .post("/api/cv/request", { emailAddress })
        .then(() => {
          setSubmitted(true);
          setSubmitting(false);
          setInputOpen(false);
          trackCustomEvent({
            category: "submit email address success",
            action: "submit",
            label: "Download CV",
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-undef
          Sentry.captureException(error);
          setSubmitting(false);
          setErrored(true);
          setInputOpen(false);
          trackCustomEvent({
            category: "submit email address error",
            action: "submit",
            label: "Download CV",
          });
        });

      trackCustomEvent({
        category: "submit email address",
        action: "submit",
        label: "Download CV",
      });
    }
  };

  const tooSmallForForTooltip = () => window.innerHeight < 300;

  const cancel = () => {
    if (!submitting) {
      setEmailAddress("");
      setInputOpen(false);

      if (typeof window !== "undefined") {
        document.dispatchEvent(new Event("virtualKeyboardClosing"));
      }

      trackCustomEvent({
        category: "download cv cancel",
        action: "click",
        label: "Download CV",
      });
    }
  };

  return (
    <div ref={emailContainer}>
      {inputOpen && (
        <div
          onClick={cancel}
          role="dialog"
          className="fixed w-full h-full inset-0 bg-gray-900 opacity-75 z-10 transition duration-500 ease-in-out"
        ></div>
      )}
      <div className="relative z-20">
        <div className="sm:flex text-lg inline-block w-full">
          {submitted ? (
            <div className="text-green-500">
              <p className="font-bold">Thanks</p>
              <p>I'll send you a link to download my CV shortly.</p>
            </div>
          ) : (
            <div>
              {inputOpen && !tooSmallForForTooltip() && (
                <Tooltip
                  ref={tooltipRef}
                  text="My CV has a lot of personal data in, I will need an email address so I can approve the request and send you a download link."
                />
              )}
              <input
                ref={inputRef}
                autoComplete={`off`}
                id="do-not-autofill-search"
                data-lpingore="true"
                disabled={submitting}
                onChange={onChange}
                onKeyPress={({ key }) =>
                  key === "Enter" ? requestDownloadLink() : {}
                }
                className={`transition-width text-white h-full bg-transparent focus:outline-none appearance-none border-b border-b-2 py-2 ${setInputClasses()}`}
                type="text"
                placeholder="email address"
              ></input>
              {submitting ? (
                <div className="spinner"></div>
              ) : (
                <button
                  onClick={
                    inputOpen ? requestDownloadLink : onDownloadCVButtonClick
                  }
                  className={`w-full sm:w-auto mt-6 sm:mt-0 font-semibold bg-transparent text-green-500 py-2 px-4 border border-green-700 focus:outline-none rounded ${setButtonClasses()}`}
                >
                  {inputOpen ? "Submit" : "Download my CV"}
                </button>
              )}
            </div>
          )}
        </div>
        {errored && (
          <div className="text-red-500 mt-1 text-sm">
            Something went wrong, please try again in a minute
          </div>
        )}
      </div>
    </div>
  );
}

export default DownloadCV;
