import React from "react";
import useTyped from "../hooks/useTyped";

const TypedText = ({ prefix = "", suffixes, showBlinker = true }) => {
  const [typedText] = useTyped({
    prefix,
    suffixes,
    showBlinker,
  });

  return (
    <div>
      {typedText}
      {showBlinker && <span className="blink">_</span>}
    </div>
  );
};

export default TypedText;
