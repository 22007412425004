import React from "react";
import TypedText from "../../typed-text";

const NpmStartSection = ({ showBlinker }) => (
  <div className="text-orange-400 mb-6">
    tom@tom-hudson.uk<span className="text-white">: </span>
    <span className="text-blue-500">~</span>
    <span className="text-white"> $ </span>
    <span className="text-white inline-block">
      <TypedText prefix="npm test" showBlinker={showBlinker} />
    </span>
  </div>
);

export default NpmStartSection;
