import React from "react";

const NpmEndedSection = ({ passing, failing }) => (
  <div className="my-6">
    <div className="w-full border-dashed border-t-2 border-gray-700"></div>
    <div className="w-100 text-center border-gray-700 my-3">
      Finished Running Tests
    </div>
    <div className="w-full border-dashed border-t-2 border-gray-700 mb-3"></div>
    <div className="text-green-500">{passing} passing</div>
    <div className="text-red-500">{failing} failing</div>
    <div className="w-full border-dashed border-t-2 border-gray-700 my-3"></div>
    <div>
      Interested in learning more about me, download my CV
      <span className="blink">_</span>
    </div>
  </div>
);

export default NpmEndedSection;
