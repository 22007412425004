import React from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const ConsoleHeading = () => (
  <div className="absolute inset-x-0 top-0 flex justify-between items-center px-2 py-1 bg-gray-700">
    <div></div>
    <div>Test Runner</div>
    <div className="flex align-middle rounded-full p-1 flex items-center justify-center items-center bg-red-600 justify-center">
      <div
        onClick={() =>
          trackCustomEvent({
            category: "console close",
            action: "click",
            label: "Console Interaction",
          })
        }
        className="icon icon-cross cursor-not-allowed leading-none text-2xs"
      ></div>
    </div>
  </div>
);

export default ConsoleHeading;
