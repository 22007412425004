import { useEffect, useRef, useState } from "react";

const initialState = {
  currentAction: "typingPrefix",
  value: "",
  nextIndex: 0,
  suffixIndex: 0,
  charsToDelete: 1,
};

const useTyped = ({
  prefix = "",
  suffixes,
  showBlinker = true,
  interval = 80,
}) => {
  let resetLock = false;

  const intervalTimer = useRef();
  const resetTimeout = useRef();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      if (!resetLock) {
        actionMap[state.currentAction]();
      }
    }, interval);

    return () => {
      if (intervalTimer.current) {
        clearInterval(intervalTimer.current);
      }
      if (resetTimeout.current) {
        clearTimeout(resetTimeout.current);
      }
    };
  }, [state]);

  const typingPrefix = () => {
    const stillTypingPrefix = state.value.length < prefix.length;

    if (stillTypingPrefix) {
      const value = state.value + prefix[state.nextIndex];

      setState({
        ...state,
        value,
        nextIndex: state.nextIndex + 1,
      });
    } else {
      if (!suffixes) {
        resetLock = true;
        return;
      }

      setState({
        ...state,
        currentAction: "typingSuffix",
      });
    }
  };

  const typingSuffix = () => {
    const finalValue = prefix + suffixes[state.suffixIndex];
    const stillTypingSuffix = state.value.length < finalValue.length;

    if (stillTypingSuffix) {
      const value = state.value + finalValue[state.nextIndex];
      const nextIndex = state.nextIndex + 1;

      setState({
        ...state,
        value,
        nextIndex,
      });
    } else {
      setState({
        ...state,
        suffixIndex:
          state.suffixIndex + 1 < suffixes.length ? state.suffixIndex + 1 : 0,
        currentAction: "resetting",
      });
    }
  };

  const resetting = () => {
    resetLock = true;

    resetTimeout.current = setTimeout(() => {
      setState({
        ...state,
        currentAction: "deletingSuffix",
      });

      resetLock = false;
      clearTimeout(resetTimeout.current);
    }, 2200);
  };

  const deletingSuffix = () => {
    const stillDeletingSuffix = state.value.length > prefix.length;

    if (stillDeletingSuffix) {
      const value = state.value.slice(
        0,
        state.value.length - state.charsToDelete
      );

      const charsToDelete = state.charsToDelete + 1;
      setState({
        ...state,
        value,
        charsToDelete,
      });
    } else {
      setState({
        ...state,
        value: prefix,
        nextIndex: prefix.length,
        charsToDelete: 1,
        currentAction: "typingSuffix",
      });
    }
  };

  const actionMap = {
    typingPrefix,
    typingSuffix,
    resetting,
    deletingSuffix,
  };

  return [state.value];
};

export default useTyped;
